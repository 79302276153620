import React, { ComponentProps, useMemo, useEffect } from 'react';
import { useSession, signOut } from 'next-auth/client';
import LogRocket from 'logrocket';
import Navbar from '@/src/shared/components/Navbar';
import { CALLBACK_URL } from '@/src/shared/config/constants';
import { useRouter } from 'next/router';
import Sentry from '@/src/lib/initSentry';
import packageJson from 'package.json';
import { titleCase } from 'title-case';
import { useGetUserOrganizationQuery } from '@/generated/graphql';
import { Flex, HStack, Tag, Text, useColorMode } from '@chakra-ui/react';

type NavbarMenuConfig = ComponentProps<typeof Navbar>['menuConfig'];

const AuthenticatedNavbar = () => {
  const router = useRouter();
  const [session] = useSession();
  const { toggleColorMode: toggleMode } = useColorMode();

  const email = session?.user?.email;
  const id = session?.account?.id;

  const { data: userOrgData } = useGetUserOrganizationQuery({
    // We must ensure that the query isn't run unless session is available
    skip: !id || !session,
    // query is used for caching
    variables: {
      auth0UserId: id,
    },
  });

  useEffect(() => {
    if (email && id) {
      LogRocket.identify(id, {
        email,
      });
      Sentry.setTag('email', email);
    }
  }, [email, id]);

  const handleTitleClick = () => {
    router.push('/');
  };

  const menuConfig: NavbarMenuConfig = useMemo(() => {
    if (session) {
      const profileMenuItems = [
        {
          label: session.user.email,
          onClick: () => {},
        },
      ];

      if (session.user.email === 'david@matternet.us') {
        profileMenuItems.push({
          label: (
            <Flex>
              <HStack>
                <Text>Toggle Theme</Text>
                <Tag size="sm" colorScheme="orange">
                  BETA
                </Tag>
              </HStack>
            </Flex>
          ),
          onClick: () => {
            toggleMode();
          },
        });
      }

      return {
        name: session.user.email,
        avatarSrc: session.user.image,
        menuGroups: [
          {
            title: 'Profile',
            menuItems: profileMenuItems,
          },

          {
            menuItems: [
              {
                label: 'Logout',
                onClick: () => {
                  signOut({
                    callbackUrl: CALLBACK_URL,
                  });
                },
              },
            ],
          },

          {
            title: `Version ${packageJson.version} - ${titleCase(
              process.env.REVISION_ENVIRONMENT || process.env.NODE_ENV,
            )}`,
            menuItems: [
              {
                label: (
                  <Text fontSize="sm">
                    &copy; {new Date().getFullYear()} Matternet, Inc.
                  </Text>
                ),
                onClick: () => {
                  window.open(
                    'https://www.mttr.net/',
                    '_blank',
                    'noopener,noreferrer',
                  );
                },
              },
            ],
          },
        ],
      };
    }

    return undefined;
  }, [session, toggleMode]);

  return (
    <Navbar
      title="MATTERNET"
      menuConfig={menuConfig}
      onTitleClick={handleTitleClick}
      userOrganizationDisplayName={userOrgData?.users[0]?.tenant?.name}
    />
  );
};

export default AuthenticatedNavbar;
